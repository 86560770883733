import * as React from 'react';
import * as ReactDOM from 'react-dom';
import tippy from 'tippy.js';
import 'tippy.js/dist/tippy.css';

import AudioPlayer from './AudioPlayer';
import FirstOcccurence from './FirstOccurrence';

import { UserData, userDataSubject } from '../../store/AuthStore';
import { ResponseStatuses, Response, createOrUpdateResponse } from '../../store/ResponseStore';
import {useEffect} from 'react';
import {assetsUri, SLIDE_WIDTH} from '../../constants/AppConstants';
import useBehaviorSubject from '../../hooks/useBehaviorSubject';
import { colorSubject } from '../../store/EnvironmentStore';
import { useRef } from 'react';

interface ParentProps {
    readonly content: string;
    readonly id?: number;
    readonly responses: Response[];
    readonly responseStatuses: ResponseStatuses;
    readonly index: number;
    readonly kapitel: number;
    readonly thema: number;
    readonly userData?: UserData;
}

type SlideProps = ParentProps

function useFormSetup(
    node: HTMLDivElement | null,
    content: string,
    kapitel: number,
    thema: number,
    id?: number,
    userData?: UserData,
) {
    useEffect(() => {
        if (!id) {
            return;
        }

        // turn off autocomplete on all inputs
        const inputs = node?.querySelectorAll('input') ?? [];
        for (const input of inputs) {
            input.autocomplete = 'off';
        }
        
        function submitHandler(event: SubmitEvent) {
            event.preventDefault();
            const form = event.target as HTMLFormElement;
            const formId = form?.getAttribute('id');
            if (!formId || !userData || !id) {
              return;
            }

            const formData = Array.from(new FormData(form))
              .reduce((accumulator: {[key: string]: string}, current) => {
                const [key, value] = current;
                if (typeof value === 'string') {
                  accumulator[key] = value;
                }
                return accumulator;
              }, {});

            let newResponse = {
                userId: userData.id,
                slideId: id,
                formId: formId,
                formData: JSON.stringify(formData),
                kapitel: kapitel,
                thema: thema
            };

            createOrUpdateResponse(newResponse);
        }
        
        const forms = node?.querySelectorAll('form') ?? [];
        for (const f of forms) {
            f.addEventListener('submit', submitHandler, false);
        }
    }, [content, id, kapitel, thema, userData, node]);
}

function useResponseSubmission(
    node: HTMLDivElement | null,
    content: string,
) {
    let mount: HTMLElement | null = null;
    useEffect(() => {
        // set up submit status
        const forms = node?.querySelectorAll('form') ?? [];
        for (const form of forms) {
            const formId = form.getAttribute('id');

            const submitElement = form.querySelector("input[type=submit]");
            if (submitElement) {
                mount = document.createElement('span');
                mount.setAttribute('id', `submit_status_${formId}`);
                submitElement.insertAdjacentElement('afterend', mount);
            };
        };
    }, [node, content]);
}

function useResponseRendering(
    node: HTMLDivElement | null,
    content: string,
    responses: Response[]) {
    useEffect(() => {
        if (responses.length === 0) {
            const forms = node?.querySelectorAll('form') ?? [];
            for (const form of forms) {
                form.reset();
            }
        }

        responses.forEach(response => {
            const form = document.getElementById(response.formId);
            const formData: Record<string, string> = JSON.parse(response.formData);
            for (const [name, value] of Object.entries(formData)) {
                const inputs = form?.querySelectorAll<HTMLInputElement>(`input[name="${name}"]`) ?? [];
                for (const input of inputs) {
                    switch (input.type) {
                        case 'radio':
                        case 'checkbox':
                            input.checked = input.value === value;
                            break;
                        case 'text':
                            input.value = value;
                            break;
                        default:
                            console.log('unknown type', input.type)
                    }
                }

                const textArea: HTMLTextAreaElement | null | undefined = form?.querySelector(`textarea[name="${name}"]`);
                if (textArea) {
                    textArea.value = value;
                }
            }
        });
    }, [responses, content, node]);
}

function useTooltipRendering(node: HTMLDivElement | null, content: string) {
    useEffect(() => {
        const tooltipElements = node?.querySelectorAll(`[data-tooltip]`) ?? [];
        for (const el of tooltipElements) {
            const tooltip = el.getAttribute('data-tooltip');
            if (tooltip) {
                tippy(el, {
                    content: tooltip,
                    duration: 100,
                    hideOnClick: false,
                });
            }
        }
    }, [node, content]);
}

function useContentNormalization(node: HTMLDivElement | null, content: string) {
    useEffect(() => {
        const rsTmbs = node?.querySelectorAll(`.rsTmb`) ?? [];
        for (const el of rsTmbs) {
            el.remove();
        }
    }, [node, content]);
}

function useVideoRendering(node: HTMLDivElement | null, content: string) {
    useEffect(() => {
        // provide data-video support for custom YouTube embed thumbnails
        const youtubeNodes = node?.querySelectorAll('.youtube') ?? [];
        for (const el of youtubeNodes) {
            const img = el.querySelector('img[data-video]');
            const src = img?.getAttribute('data-video');
            const width = img?.getAttribute('width');
            const height = img?.getAttribute('height');
            const video = `<iframe src="${src}" width="${width}" height="${height}" frameborder="0" allowfullscreen></iframe>`;
            el.replaceWith(video);
        }
    }, [node, content]);
}

function useAudioRendering(content: string, color: string, id?: number) {
    useEffect(() => {
        const slideId = `slide_${id}`;
        const audioIds = new Set<string>();

        // audio player setup
        const audioPlayerElements = document.querySelectorAll(`#${slideId} a.audio-player`);
        audioPlayerElements.forEach((el: Element) => {
            const audioSrc = el.getAttribute('data-audio');
            const audioId = `${audioSrc}_${slideId}`;
            if (!audioIds.has(audioId)) {
                audioIds.add(audioId);
                const audioElement = document.getElementById(audioId);
                if (!audioElement) {
                    el.insertAdjacentHTML('afterend', `<div id="${audioId}"></div>`)
                }
                ReactDOM.render(
                    <AudioPlayer
                        src={audioSrc || ''}
                        color={color}/>,
                    document.getElementById(audioId));
            }
        });

        // first occurrence audio
        const firstOccurrenceElements = document.querySelectorAll(`#${slideId} .firstOccurrence`);
        firstOccurrenceElements.forEach((el: Element) => {
            const audioSrc = el.getAttribute('data-audio');
            const tooltip = el.getAttribute('data-tooltip');
            const text = el.innerHTML;
            const tooltipId = `${slideId}_${text}`;
            el.insertAdjacentHTML('afterend', `<span id="${tooltipId}"></span>`);
            el.replaceWith('');
            ReactDOM.render(<FirstOcccurence
                audioSrc={audioSrc}
                text={text}
                tooltip={tooltip}
                color={color}
                assetsUri={assetsUri} />, document.getElementById(tooltipId));
        });

        const audioElements = document.querySelectorAll(`#${slideId} [data-audio]`);
        Array.prototype.forEach.call(audioElements, (el: Element) => {
            el.addEventListener('click', () => {
                const audioFile = el.getAttribute('data-audio');
                if (audioFile) {
                    const audio = new Audio(audioFile);
                    audio.play();
                }
            });
        });

        return () => {
            audioIds.forEach(apid => {
                const domNode = document.getElementById(apid);
                if (domNode) {
                    ReactDOM.unmountComponentAtNode(domNode);
                }
            });
        };
    }, [id, color, content]);
}

const Element = (props: SlideProps) => {
    let {
        content,
        index,
        responses,
        kapitel,
        thema
    } = props;
    
    const color = useBehaviorSubject(colorSubject);
    const userData = useBehaviorSubject(userDataSubject);
    const node = useRef(null);
    const id = `slide_${props.id}`;

    useFormSetup(node.current, content, kapitel, thema, props.id, userData ?? undefined);
    useResponseSubmission(node.current, content);
    useResponseRendering(node.current, content, responses);
    useTooltipRendering(node.current, content);
    useVideoRendering(node.current, content);
    useContentNormalization(node.current, content);
    useAudioRendering(content, color, props.id);

    if (content) {
        content = content.replace(/\/images\/kapitel/g, `${assetsUri}/images/kapitel`);
        content = content.replace(/\/audio\/kapitel/g, `${assetsUri}/audio/kapitel`);
        content = content.replace(/\/video\/kapitel/g, `${assetsUri}/video/kapitel`);
        content = content.replace(/\/assets\/popups/g, `${assetsUri}/popups`);
    }

    const style = {
        left: `${SLIDE_WIDTH * index}px`
    };

    return <div className={`block top-0 absolute w-[860px] pl-12 pr-16 py-12 slide text-lg overflow-y-auto h-full slide`}
        ref={node}
        style={style}
        id={id}
        dangerouslySetInnerHTML={{__html: content}}/>
}

Element.defaultProps = {
    index: 0,
    responses: [],
    responseStatuses: {}
}

export default Element;
