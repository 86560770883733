
const markdown =`
## 1. Accessing the site

**der|die|das** is a password-protected site. In order to use it, you must be on the access list,
which is restricted to users in participating institutions and invited guests. To request access as
a guest, please contact the site administrator by clicking on the Kontakt link at the top of this
page. Once access has been granted, the administrator will send you information regarding the
sign-in / log-in process.
</div>

## 2. Navigating the site

The home page contains all the navigational tools you need to get started, and has various elements
that function together as a kind of digital “Table of Contents.” First, the items at the very top of
the page:

Inhalt _(contents):_ Clicking on this produces a drop-down menu of all 16 chapters, with additional
drop-down menus that connect you with a click to the indicated grammar/structure section in the
chapter.

**Kontakt:** This activates a pop-up email window to contact the person who oversees the site.

**Suchen** _(search):_ You can type any word (or part of a word) from the core vocabulary into  this
space, and the program will show you every entry in the database that contains that word or those
letters. Clicking on the numbers inside the accompanying parentheses will take you to the **Thema**
where that word is introduced. (This function is only available once you’ve logged into the
program.)

**der|die|das:** This is the “home” button that appears on every page, and will bring you back to
the home page.

Now, on to three elements of the individual chapters: **Themen** _(topics)_, vocabulary and
interactive responses:

### Thema:

Each chapter engages a particular cultural topic, organized into four sub-sections, each of which is
called a **Thema** _(topic, theme)_.  Clicking an image on the home page takes you to the first of
**Thema** in that chapter, and the numbers (1-4) at the top of that page provide access to the other
three. Additional chapter-level navigation is available via the **Inhalt** button at the top of the
page (once you’re in a chapter, and no longer on the home page), taking you via the drop-down menus
to the **Themen**, or to a specific grammar/structure section within that **Thema**.

Each **Thema** in turn consists of three parts: 

* **Interaktionen I:** Interactive modules that explore the cultural topic by way of texts, images,
  video and audio resources. Embedded into these modules are all of the new core vocabulary words
  for that **Thema** (highlighted in color) as well the grammatical features to be introduced.
  Everything in these modules is presented in German:  Either you’ve learned the words previously
  and are expected to know them; or they’re highlighted in color, indicating that they’re new
  vocabulary (and clickable for an audio rendition); or they’re marked with a small circle to
  indicate that a mouseover will activate an English gloss. In other words, if you’ve kept up, you
  should be able to understand everything on the page.
* **Strukturen:** Explanations (in English) of new grammatical structures, followed by **Übungen**
  _(exercises)_ that help you consolidate what you’ve learned about them.
* **Interaktionen II:** Additional cultural modules, similar in format to **Interaktionen I**, but
  containing no new core vocabulary or grammar. The first module in this set is a targeted review of
  previously learned vocabulary, designed to recycle and consolidate the words you’ve learned so
  far.

### Vocabulary:

The core vocabulary words – 80 per chapter, on average – can be identified and accessed four ways:

* Click on a color-highlighted word embedded in **Interaktionen I**. A mouseover gives you an
  English gloss; and clicking on the word activates an audio recording of that particular form, so
  you’ll know how to pronounce it.
* Click on the **Wortschatz** _(vocabulary)_ button at the top right corner of each **Interaktionen
  / Strukturen** window. This will activate a pop-up window showing an alphabetized list of all the
  new words introduced in that **Thema**, including plural forms for nouns and principal parts for
  verbs. Here too the highlighted words can be clicked for an audio recording.
* Click on the **Wortschatz** _(vocabulary)_ button at the very top of each page within a chapter to
  activate a pop-up window with an alphabetized list (clickable for audio) of all the words in that
  chapter.
* Type a word (or part of a word) into the text area marked **Suchen** _(search)_ at the very top of
  the page in order to retrieve the word’s basic meaning(s) and the **Thema** where it is
  introduced. Clicking on the accompanying chapter/**Thema** numbers – Example: **2 T4** – will take
  you directly to that **Thema**.

Other vocabulary words: Words that you are not expected to know – either because they are not in the
core vocabulary,  or haven’t been introduced as such yet – are marked with a small circle (similar
to an asterisk, and a mouseover activates a pop-up English gloss. German words that are cognate with
English are not marked like this, since we assume you can guess their meaning from the form and
context.

### Responses:

The **Interaktionen** and **Übungen** _(exercises)_ contain four possible response options: 

* checkboxes: multiple answers can be clicked on
* radio buttons: only one correct answer is expected, so only one of these can be clicked
* short texts (a word, a phrase, or perhaps a short sentence) typed into short text boxes
* longer texts, i.e., 3-4 sentences or more, typed into larger text boxes

Clicking on **Speichern** _(save)_ will send your responses to a database where they can be viewed
by your instructor. We’ve programmed **der|die|das** so that you can update and revise your
responses at any time; but you’ll need to click on **Speichern** again to save them.

NB: You must save your responses before leaving a page or they will be lost, i.e., they won’t be
stored in the database, nor will they appear on the page when you return to it. And be sure that
you’re entering your responses in only one browser window, rather than working on an **Interaktion**
or **Übung** in multiple windows.

## 3. Concept

About the curriculum and the concept behind it:

**der|die|das** is designed to integrate three critical components of language acquisition:

* vocabulary
* grammar/syntax
* culture

Learning vocabulary teaches you what words mean; learning grammar and syntax shows you how to shape
and sequence these words to express meaning; and learning about culture enables you to understand
how this meaning is embedded in a socio-cultural context.

**der|die|das** includes all three components, but focuses in particular on acquiring vocabulary
–specifically the most frequently used vocabulary in German, as set out in **A Frequency Dictionary
of German** (Jones &amp; Tschirner; Routledge, 2006). Vocabulary acquisition experts agree that
learning the 1,000 most frequently used word families in a language allows you to comprehend up to
70-75% of almost any written text, and up to 90% of most spoken language. To that end, this book
focuses on a “core vocabulary” of the most frequently used 1,200 words by presenting and recycling
these words in various ways.

The 80 core vocabulary words in each chapter (on average) are distributed more or less evenly across
the four **Themen**. The navigation guide above explains how you can access these words, and how the
program recycles them – especially words that differ markedly from English – from chapter to chapter
throughout the text. This occurs explicitly in the first module of every **Interaktionen II**
    section; but it is also a feature of the grammar explanations. When past-tense verbs forms are
introduced, for example, you’ll review all of the core vocabulary verbs you’ve learned up to that
    point; and exercises that focus on adjective endings will recycle relevant adjectives from the
core list. Besides that, you’ll find that the language used in the cultural topics (advertisments,
short stories, news media, etc.) naturally contains these words as well, so that if you’re paying
attention, you’ll see them used over and over.

Learning all the vocabulary will require more than just working through the **Interaktionen** and
**Übungen**, however. You should plan from the very beginning to review and use the new words on
your own, with the help of online flashcard apps, such as <strong>Anki</strong> or the
<strong>Quizlet</strong> flashcards at the bottom of each chapter-level vocabulary list; paper
flashcards; “Post-em”-style notes around your room; reading headlines from online newspapers and
listening to YouTube videos; and writing original sentences that incorporate these new words. There
are many options, and no two people will find the same one equally useful, so it’s up to you to find
what works best for you. Whatever method you choose, it’s essential that you use this frequently,
and that you keep up with the syllabus. If you do, at the end of the program you’ll have a powerful
tool for engaging the German-speaking world at a deep level, which is the goal of this curriculum.
`

export default markdown;
