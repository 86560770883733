import React, { useEffect } from 'react';

import VocabWord from './VocabWord';
import { getObjectValues } from '../../utilities';
import useBehaviorSubject from '../../hooks/useBehaviorSubject';
import { borderThemaSubject, kapitelSubject, textThemaSubject, themaSubject } from '../../store/EnvironmentStore';
import { requestVocab, vocabSubject, Vocab } from '../../store/VocabStore';

const ThemaVocabPage = () => {
    const kapitel = useBehaviorSubject(kapitelSubject);
    const thema = useBehaviorSubject(themaSubject);
    const vocab = getObjectValues(useBehaviorSubject(vocabSubject))
        .filter(x => x.kapitel === kapitel && x.thema === thema);
    const textThema = useBehaviorSubject(textThemaSubject);
    const borderThema = useBehaviorSubject(borderThemaSubject);

    useEffect(() => {
        requestVocab(kapitel, thema);
    }, [kapitel, thema]);


    if (vocab.length === 0) {
        return <div/>;
    }

    const localVocab = vocab
        .filter(vocab => vocab.kapitel === kapitel && vocab.thema === thema)
        .sort((a: Vocab, b: Vocab) => a.word.toLowerCase().localeCompare(b.word.toLowerCase()));
    
    /* We need to figure out what embed code to load - this should maybe
    be offloaded to the database to cut down	on the code needed here. */
    const embedCodes = [
        ["145392636", "145392869", "145393234", "145393259"], /* 1  */
        ["146383850", "146384590", "146384604", "146384615"], /* 2  */
        ["146384645", "146384668", "146384680", "146384703"], /* 3  */
        ["146401353", "146401394", "146401444", "146401494"], /* 4  */
        ["146401524", "146401688", "146401610", "146401801"], /* 5  */
        ["146402096", "146402161", "146402182", "146402213"], /* 6  */
        ["146402265", "146402385", "146402417", "146402462"], /* 7  */
        ["146402497", "146402530"],                           /* 8  */
        ["146402558", "146402636", "146402681", "146402709"], /* 9  */
        ["146402747", "146402792", "146402818", "146402839"], /* 10 */
        ["146402869", "146402899", "146402935", "146402971"], /* 11 */
        ["146403013", "146403052", "146403079", "146403109"], /* 12 */
        ["146403135", "146403170", "146403204", "146403226"], /* 13 */
        ["146403250", "146403291", "146403315", "146403342"], /* 14 */
        ["146403433", "146403459", "146403476", "146403495"], /* 15 */
        ["146403529", "146403555"]                            /* 16 */
    ];

    let embedCode = "";
    if (kapitel - 1 < embedCodes.length && thema - 1 < embedCodes[kapitel - 1].length) {
        embedCode = embedCodes[kapitel - 1][thema - 1];
    }

    return (
        <div className={'w-full'}>
            <div className='bg-black text-white h-20 grid uppercase font-sans'>
                <div className='w-10/12 place-self-center'>
                    <h3>WORTSCHATZ</h3>
                    <h4>KAPITEL {kapitel}: THEMA {thema}</h4>
                </div>
            </div>

            <div className='grid'>
                <div className='w-10/12 place-self-center divide-y divide-stone-300 font-serif text-lg'>
                {
                    localVocab.map((word) => <div className='py-2'><VocabWord vocab={word} textClass={textThema} key={word.word}/></div>)
                }
                </div>

                <div className='w-10/12 place-self-center'>
                    <div className={`mt-4 border-double border-t-8 ${borderThema} h-8 w-full`}/>
                    <h3>ÜBUNGEN</h3>
                    <iframe title="quizlet" src={"https://quizlet.com/" + embedCode + "/flashcards/embed"} height="400" width="100%" style={{border:0}}></iframe>
                </div>
            </div>
        </div>
    );
}

export default ThemaVocabPage;
