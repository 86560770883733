import React from 'react';
import { HashLink as Link } from './Core/HashLink';

import KapitelLink from './Layout/KapitelLink';
import FullLayout from './Layout/FullLayout';
import Arrow from './Utilities/Arrow';
import { getThemaColor } from '../constants/AppConstants';

const Index: React.FunctionComponent = () => {
    return (
        <FullLayout>
            <div className='grid bg-neutral'>
                <section className="w-content place-self-center grid grid-cols-3 gap-x-6 mt-14">
                    <div className='col-span-2 bg-white px-10 pt-6 pb-14 font-open text-[5rem] relative'>
                        <span>der</span>
                        <div className={`relative top-6 mx-4 w-3.5 h-28 inline-block bg-thema1`}/>
                        <span>die</span>
                        <div className={`relative top-6 mx-4 w-3.5 h-28 inline-block bg-thema1`}/>
                        <span>das</span>
                        <div className={`w-16 h-3.5 bg-thema1 absolute left-[36rem] top-[-0.9rem]`}/>
                        <div className={`w-3.5 h-16 bg-thema1 absolute left-[39.5rem] top-[-0.9rem]`}/>
                        <div/>
                    </div>
                    <div className='col-span-2'>
                        <Link className='bg-thema1 p-2.5 float-right text-white font-sans' to='/info'>
                            <div className='flex justify-center items-center leading-none'>INFO</div>
                        </Link>
                    </div>
                </section>
                <section className="w-content place-self-center flex mt-14 mb-14">
                    <div className="grid grid-cols-6 gap-6 w-full">
                        <KapitelLink kapitel={1} title="Smalltalk" widthClass='col-span-2'/>
                        <KapitelLink kapitel={2} title="Vernetzt" widthClass='col-span-2'/>
                        <KapitelLink kapitel={3} title="Sonntag" widthClass='col-span-2'/>
                        <KapitelLink kapitel={4} title="Mahlzeit" widthClass='col-span-3'/>
                        <KapitelLink kapitel={5} title="Autobahn" widthClass='col-span-3'/>
                        <KapitelLink kapitel={6} title="Heimat" widthClass='col-span-3'/>
                        <KapitelLink kapitel={7} title="Sportsachen" widthClass='col-span-3'/>
                        <KapitelLink kapitel={8} title="Großstadt" widthClass='col-span-3'/>
                        <KapitelLink kapitel={9} title="Mallorca" widthClass='col-span-3'/>
                        <KapitelLink kapitel={10} title="Kindheit" widthClass='col-span-2'/>
                        <KapitelLink kapitel={11} title="Bildung" widthClass='col-span-2'/>
                        <KapitelLink kapitel={12} title="Grenzen" widthClass='col-span-2'/>
                        <KapitelLink kapitel={13} title="Berlin" widthClass='col-span-3'/>
                        <KapitelLink kapitel={14} title="Geld" widthClass='col-span-3'/>
                        <KapitelLink kapitel={15} title="Lederhosen" widthClass='col-span-3'/>
                        <KapitelLink kapitel={16} title="Amerika" widthClass='col-span-3'/>
                    </div>
                </section>
                <Arrow point='up' href='#thestart' color={getThemaColor(1)} baseWidth='5rem' sidesWidth='5rem' radius='0.0rem' className='place-self-center'/>
            </div>
        </FullLayout>
    );
}

export default Index
