import { BehaviorSubject } from 'rxjs';
import { getJson } from '../utilities/Requests';
import { apiUri, RequestStatus } from '../constants/AppConstants';
import { apiCall } from '../utilities/RequestHelpers';

export interface Thema {
    kapitel: number;
    thema: number;
    name: string;
    renderVocab: boolean;
}

export const themasSubject = new BehaviorSubject<Thema[]>([]);
export const themaRequestStatusSubject = new BehaviorSubject<RequestStatus>(null);
let requestedThemas = false;

export const requestThemas = async () => {
    if (requestedThemas) {
        return;
    }

    await apiCall(async () => {
        const themas = await getJson<Thema[]>({ url: `${apiUri}/themas`, });
        themasSubject.next(themas);
        requestedThemas = true;
    }, themaRequestStatusSubject);
}
