import { BehaviorSubject } from 'rxjs';
import { getJson } from '../utilities/Requests';
import { apiUri, RequestStatus } from '../constants/AppConstants';
import { apiCall } from '../utilities/RequestHelpers';

export interface Kapitel {
    kapitel: number;
    name: string;
}

export const kapitelsSubject = new BehaviorSubject<Kapitel[]>([]);
export const kapitelRequestStatusSubject = new BehaviorSubject<RequestStatus>(null);
let requestedKapitels = false;

export const requestKapitels = async () => {
    if (requestedKapitels) {
        return;
    }

    await apiCall(async () => {
        const kapitels = await getJson<Kapitel[]>({ url: `${apiUri}/kapitels`, });
        kapitelsSubject.next(kapitels);
        requestedKapitels = true;
    }, kapitelRequestStatusSubject);
}