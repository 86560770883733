import React, { useEffect } from 'react';
import { HashLink as Link } from '../Core/HashLink';

import { letters } from '../../constants/AppConstants';
import VocabSection from './VocabSection';
import FullLayout from '../Layout/FullLayout';
import { getObjectValues } from '../../utilities';
import { requestVocab, vocabSubject, Vocab } from '../../store/VocabStore';
import useBehaviorSubject from '../../hooks/useBehaviorSubject';
import { borderThemaSubject, colorSubject, kapitelSubject, textThemaSubject } from '../../store/EnvironmentStore';
import { locationSubject } from '../../history';
import Arrow from '../Utilities/Arrow';

const KapitelVocabPage = () => {
    const kapitel = useBehaviorSubject(kapitelSubject);
    const location = useBehaviorSubject(locationSubject);
    const vocab = getObjectValues(useBehaviorSubject(vocabSubject))
        .filter(x => x.kapitel === kapitel);
    const textThema = useBehaviorSubject(textThemaSubject);
    const color = useBehaviorSubject(colorSubject);
    const borderThema = useBehaviorSubject(borderThemaSubject);

    useEffect(() => {
        requestVocab(kapitel, null);
    }, [kapitel]);

    const pathname = location ? location.pathname : '';

    if (!vocab)
        return <div/>;

    let vocabByLetter: {[id: string]: Vocab[]} = {};

    letters.forEach(l => vocabByLetter[l] = []);
    vocab.forEach(v => {
        vocabByLetter[v.letter.toUpperCase()[0]].push(v);
    });

    const validLetters: string[] = Object.keys(vocabByLetter);

    let jsxWords: any[] = [];
    for (let letter in vocabByLetter) {
        const words = vocabByLetter[letter];
        jsxWords.push(
            <div key={letter}>
                <VocabSection letter={letter} vocab={words} location={location} textClass={textThema}/>
            </div>
        );
    }

    /* We need to figure out what embed code to load - this should maybe
    be offloaded to the database to cut down on the code needed here. */
    // TODO: refactor this
    const embedCodes = ["145393361", "146384637", "146384738", "146404059", "146404030", "146403996", "146403971", "146403911", "146403885", "146403853", "146403812", "146403786", "146403751", "146403713", "146403660", "146403617"];
    
    let embedCode = "";
    if (kapitel - 1 < embedCodes.length) {
        embedCode = embedCodes[kapitel - 1];
    }

    return (
        <FullLayout>
            <div id="thestart" className='w-full'>
                <div className='bg-stone-700 text-white h-40 grid uppercase font-sans pb-10'>
                    <div className='w-content place-self-center'>
                        <h2>WORTSCHATZ | KAPITEL {kapitel}</h2>
                    </div>
                    <div className={`w-content place-self-center divide-x font-serif text-2xl ${textThema} flex`}>
                    {
                        validLetters.map(function(letter) {
                            return <div key={letter} className='w-8 grid place-content-center'>
                                <Link className='' to={{ pathname, hash: '#' + letter }}>{letter}</Link>
                            </div>
                        })
                    }
                    </div>
                </div>
                <div className="grid mt-12">
                    <div className='w-content place-self-center'>
                        {jsxWords}
                    </div>
                </div>
                <div className='grid'>
                    <div className='w-content place-self-center mb-12'>
                        <div className={`mt-4 border-double border-t-8 ${borderThema} h-8 w-full`}/>
                        <h3>ÜBUNGEN</h3>
                        <iframe title="quizlet" src={"https://quizlet.com/" + embedCode + "/flashcards/embed"} height="400" width="100%" style={{border:0}}></iframe>
                    </div>
                    <Arrow point='up' href='#thestart' color={color} baseWidth='5rem' sidesWidth='5rem' radius='0.0rem' className='place-self-center'/>
                </div>
            </div>
        </FullLayout>
    );
}

export default KapitelVocabPage
